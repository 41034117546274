import React, { Component } from "react"
import style from "./card.module.scss"
import * as SVG from "../../svgs.js"
import InlineSVG from "svg-inline-react"
import { Modal } from "semantic-ui-react"

class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reveal: false,
    }
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({
          appear: true,
        })
      }.bind(this),
      5000
    )
  }

  openModal() {
    this.setState({
      modal: true,
    })
  }

  closeModal = e => {
    console.log("clicked")
    this.setState({
      modal: false,
    })
  }

  render() {
    const title = this.props.title
    const description = this.props.description

    let svg = ""

    switch (title) {
      case "Loss":
        svg = SVG.loss
        break
      case "Love":
        svg = SVG.love
        break
      case "Life":
        svg = SVG.life
        break
      case "Tower":
        svg = SVG.tower
        break
      case "Afterlife":
        svg = SVG.afterlife
        break
      case "Marriage":
        svg = SVG.marriage
        break
      case "Spectre":
        svg = SVG.spectre
        break
      case "Oracle":
        svg = SVG.oracle
        break
      case "Mischief":
        svg = SVG.mischief
        break
      case "Writer":
        svg = SVG.writer
        break
      case "Triptych":
        svg = SVG.triptych
        break
      case "Children":
        svg = SVG.children
        break
      case "Moon":
        svg = SVG.moon
        break
      default:
        svg = ""
        break
    }

    return (
      <div>
        <div
          role="button"
          tabIndex={this.props.id}
          className={
            style["animate__" + this.props.id] +
            " " +
            (this.props.reveal ? style.card + " " + style.flip : style.card)
          }
          onClick={this.openModal.bind(this)}
          onKeyDown={this.openModal.bind(this)}
        >
          <div className={style.front}></div>
          <div className={style.back}>
            <InlineSVG src={svg} />
          </div>
        </div>
        <Modal basic open={this.state.modal} onClose={this.closeModal}>
          <Modal.Content>
            <div className={style.card_modal + " row"}>
              <div className="col col-6">
                <h3 className="heading--l">The {title} card</h3>
                <p className="body--l">{description}</p>
              </div>
              <div className={style.card_modal__card + " col col-6"}>
                <button
                  onClick={this.closeModal}
                  className={style.card_modal__close}
                >
                  Close &#x2715;
                </button>
                <InlineSVG src={svg} />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default Card
